import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ResourceSetProvider } from '../context/ResourceSetContext';

import '../components/base.css';
import {
  Nav,
  Menu,
  MenuCard,
  MenuIcon,
  NavIcon,
  theme,
} from '@sygnia/components';
import {
  getMenuSilos,
  setupPage,
  mapperWithFunction,
  setupLink,
} from '../common/utils';
import { Flex, Box } from 'rebass';
import { Silo, Notice } from '@sygnia/components';
import get from 'lodash/get';
import { FooterMenuWrapper } from '../modules/FooterMenuWrapper';
import MenuFooter from '../components/MenuFooter';
import { MenuWrapper } from './MenuWrapper';
import { CONTENT_TYPES, CONTENT_TITLES, SILO_SLUGS } from '../common/constants';
import { Link } from 'gatsby';
import SearchMenu from '../components/search/ui/SearchMenu';

const MENUCARD_MAP = {
  [CONTENT_TYPES.CONTENT_PAGE]: {
    title: ['pageSummary', 'title'],
    content: ['pageSummary', 'content', 'childMarkdownRemark', 'html'],
    link: value => {
      return setupPage(value, {});
    },
    color: ['pageSummary', 'colorScheme'],
  },
  [CONTENT_TYPES.GENERAL_CARD]: {
    title: ['title'],
    content: ['content', 'childMarkdownRemark', 'html'],
    link: ['links'],
    link: value => {
      if (value.links && value.links.length) {
        return setupLink(value.links[0]);
      }
      return null;
    },
    colorScheme: ['colorScheme'],
  },
};

const Main = props => {
  const [isActive, setActive] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isSiloActive, setActiveSilo] = useState(false);
  const [navTextColor, setNavTextColor] = useState('baseWhite');

  const { page } = props;

  const footerMenu = get(props, 'pageContext.footerMenu');
  const homePage = get(props, 'pageContext.homePage');
  const globalMenus = get(props, 'pageContext.globalMenus');
  const globalNotices = get(props, 'pageContext.globalNotices');
  const subpageMenus = get(props, 'pageContext.subpageMenus');
  const siloMenu = get(props, 'pageContext.siloMenu');
  const silos = getMenuSilos(siloMenu.menuItems);
  const parts = [];

  if (page.parent) {
    parts.push(page.parent.replace('/', ''));
  }

  if (page.slug) {
    parts.push(page.slug.replace('/', ''));
  }

  const activeSilo = homePage.subPages.find(p => {
    return parts.includes(p.slug);
  });

  const m = subpageMenus.find(m =>
    m.node.location.includes(page.parent || page.slug),
  );

  let sub = [];
  if (m && m.node.menuItems) {
    sub = m.node.menuItems;
  }

  const ctas =
    sub &&
    sub.map((p, index) => {
      if (CONTENT_TYPES.CONTENT_PAGE === p.__typename && !p.pageSummary)
        return null;

      if (!MENUCARD_MAP[p.__typename]) return null;

      const summary = mapperWithFunction(p, MENUCARD_MAP[p.__typename]);
      const menuCardColor = p.colorScheme || summary.color;

      return (
        <MenuCard
          titleColor={`${menuCardColor}Bright`}
          isHovered={menuCardColor}
          {...summary}
          spacing={{
            mt: index === 0 ? [3, 3, 10] : [3, 3, 6],
            mb: index === 2 ? [3, 3, 10] : [3, 3, 6],
            pl: [0, 0, 2],
            mr: [0, 0, 16],
          }}
        />
      );
    });

  const menus = {
    forMe: globalMenus.find(m => m.node.location.includes('For Me')).node,
    // forMyChildren: globalMenus.find(m => m.node.location.includes('For my children')).node,
    forMyBusiness: globalMenus.find(m =>
      m.node.location.includes('For my business'),
    ).node,
    institutional: globalMenus.find(m =>
      m.node.location.includes('Institutional'),
    ).node,
    resources: globalMenus.find(
      m =>
        m.node.location.includes('Global') &&
        m.node.title.includes('Resources'),
    ).node,
    resourcesForMe: globalMenus.find(
      m =>
        m.node.location.includes('For Me') &&
        m.node.title.includes('Resources'),
    ).node,
    resourcesForInstitutions: globalMenus.find(
      m =>
        m.node.location.includes('Institutional') &&
        m.node.title.includes('Resources'),
    ).node,
    resourcesForMyBusiness: globalMenus.find(
      m =>
        m.node.location.includes('For my business') &&
        m.node.title.includes('Resources'),
    ).node,
    documents: globalMenus.find(m => m.node.title.includes('Documents')).node,
    invest: globalMenus.find(m => m.node.title === 'More ways to invest').node,
    about: globalMenus.find(m => m.node.title === 'About').node,
  };

  let resourceMenu = menus.resources;
  //Global resources menu will show, unless active silo is one of the 3 below:
  if (activeSilo) {
    if (activeSilo.slug === SILO_SLUGS.business) {
      resourceMenu = menus.resourcesForMyBusiness;
    } else if (activeSilo.slug === SILO_SLUGS.institutional) {
      resourceMenu = menus.resourcesForInstitutions;
    } else if (activeSilo.slug === SILO_SLUGS.me) {
      resourceMenu = menus.resourcesForMe;
    }
  }

  const menuContent = [];

  if (ctas.length) {
    menuContent.push({
      width: [1, 1, 12 / 24],
      mb: [0, 0, 12],
      components: ctas || [],
    });
  }

  menuContent.push(
    {
      width: [1, 1, ctas.length ? 6 / 24 : 12 / 24],
      components: [
        <Flex
          key={3}
          flexDirection="column"
          py={[0, 0, 10]}
          pl={[0, 0, 5]}
          pr={[0, 0, 2]}
          mt={[1, 1, 0]}
        >
          <MenuWrapper
            isFeatured={true}
            section={{
              title: resourceMenu.title,
              items: resourceMenu.menuItems,
            }}
          />
          <MenuWrapper
            isFeatured={true}
            section={{
              title: menus.documents.title,
              items: menus.documents.menuItems,
            }}
          />
        </Flex>,
      ],
    },
    {
      width: [1, 1, ctas.length ? 6 / 24 : 12 / 24],
      components: [
        <Flex
          key={3}
          flexDirection="column"
          py={[0, 0, 10]}
          pl={[0, 0, 5]}
          pr={[0, 0, 2]}
          mb={[1, 1, 0]}
        >
          <MenuWrapper
            section={{
              title: menus.invest.title,
              items: menus.invest.menuItems,
            }}
          />
          <MenuWrapper
            section={{
              title: menus.about.title,
              items: menus.about.menuItems.slice(0, 4),
            }}
          />
        </Flex>,
      ],
    },
  );

  return (
    <ThemeProvider theme={theme}>
      <ResourceSetProvider>
        <>
          {globalNotices &&
            globalNotices.map((item, idx) => {
              return (
                <Notice
                  key={`globalNotice-${idx}`}
                  meta={
                    item.node.meta
                      ? JSON.parse(item.node.meta.internal.content)
                      : {}
                  }
                  isActive={item.node.isActive}
                  isDismissible={item.node.isDismissible}
                  text={item.node.content.content}
                />
              );
            })}

          <Nav
            onSiloToggle={() => {
              setActive(false);
              setActiveSilo(!isSiloActive);
            }}
            siloName={
              activeSilo
                ? activeSilo.pageSummary.title.toLowerCase()
                : 'for all'
            }
            isSiloActive={isSiloActive}
            links={[
              {
                text: CONTENT_TITLES.openAccount,
                hideOnMobile: true,
                to: 'https://online.sygnia.com/Alchemy/Account/SignUp',
                external: true,
                forwardedAs: Link,
              },
              {
                text: 'Help',
                hideOnMobile: true,
                to: '/faq',
                forwardedAs: Link,
              },
              {
                text: CONTENT_TITLES.logIn,
                hideOnMobile: true,
                to: '/log-in',
                forwardedAs: Link,
              },
              {
                text: 'Search',
                hideOnMobile: true,
                icon: <NavIcon name="search" />,
                onClick: () => {
                  setSearchOpen(!isSearchOpen);
                },
              },
              {
                text: isActive ? 'Close' : 'Menu',
                hideOnMobile: false,
                icon: <MenuIcon isMenuActive={isActive} />,
                onClick: () => {
                  setActive(!isActive);
                  setActiveSilo(false);
                },
              },
            ]}
            textColor={navTextColor}
            transitionStatus={props.transitionStatus}
            shouldAnimate={true}
          />
          <Menu
            offset={75}
            content={menuContent}
            isActive={isActive}
            isSiloActive={isSiloActive}
            footer={<MenuFooter />}
          />

          <Silo
            silos={silos}
            isFixed
            isActive={isSiloActive}
            setNavTextColor={setNavTextColor}
            transitionStatus={props.transitionStatus}
            shouldAnimate={true}
            setActiveSilo={setActiveSilo}
          />
          <Box bg={props.pageBackgroundColor ? props.pageBackgroundColor : ''}>
            {props.children}
          </Box>

          <FooterMenuWrapper menu={footerMenu} />
          <SearchMenu
            isSearchOpen={isSearchOpen}
            onSearchToggle={setSearchOpen}
          />
        </>
      </ResourceSetProvider>
    </ThemeProvider>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  pageBackgroundColor: PropTypes.string,
};

Main.defaultProps = {
  pageBackgroundColor: '',
};
export default Main;
