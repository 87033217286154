import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled, { css } from 'styled-components';
import { Container } from '../../components/Grid';
import { silos, siloColorScheme } from './common';
import { theme } from '../common/theme';
import SiloLinkList from './SiloLinkList';
//import siloCursor from './silo-cursor.png';

// TODO: change cursor to svg
const Wrapper = styled(Box)`
  max-height: ${({ isActive }) => (isActive ? '100vh' : '0px')};
  overflow: hidden;
  transition: background-color ${theme.speed.default} ease,
    max-height ${theme.speed.default} ease;
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      top: 210px;
      z-index: 80;
      bottom: 0;
    `}
`;
/* cursor: url(${siloCursor}), auto; */
const Silo = ({
  silos,
  isFixed,
  isActive,
  isMobile,
  setNavTextColor,
  transitionStatus,
  shouldAnimate,
  setActiveSilo,
}) => {
  const [color, setColor] = useState('white');
  const colors = siloColorScheme[color] || siloColorScheme['white'];
  const { bg, logoColor } = colors;

  useEffect(() => setNavTextColor(logoColor), [logoColor]);

  const entering = ['entering', 'entered'].includes(transitionStatus);

  return (
    <Wrapper
      data-testid="silo-wrapper"
      className="silo-wrapper"
      bg={bg}
      width="100vw"
      isActive={isActive}
      isFixed={isFixed}
    >
      <Container>
        <Box py={[4, 5]} mt={[10]}>
          <SiloLinkList
            silos={silos}
            setColor={setColor}
            colors={colors}
            isMobile={isMobile}
            isActive={isActive}
            entering={entering}
            shouldAnimate={shouldAnimate}
            setActiveSilo={setActiveSilo}
          />
        </Box>
      </Container>
    </Wrapper>
  );
};

Silo.propTypes = {
  /** Is the menu active */
  isActive: PropTypes.bool,
  /** Set the active state */
  setIsActive: PropTypes.func,
  /** Is this the mobile view? */
  isMobile: PropTypes.bool,
  /** Is this the a fixed view? */
  isFixed: PropTypes.bool,
  /** Array of silo links */
  silos: PropTypes.array,
  /** Callback to change nav text color */
  setNavTextColor: PropTypes.func,
  /** Transition status of parent page */
  transitionStatus: PropTypes.string,
  /** Should the Nav animate? */
  shouldAnimate: PropTypes.bool,
  /** set whether the silo is active or not */
  setActiveSilo: PropTypes.func,
};

Silo.defaultProps = {
  isFixed: false,
  isActive: false,
  setIsActive: null,
  isMobile: false,
  silos: silos,
  setNavTextColor: () => {},
  transitionStatus: '',
  shouldAnimate: false,
  setActiveSilo: () => {},
};

export default Silo;
