import React from 'react';
import { Table } from '@sygnia/components';
import LinkGroup, { BUTTON_TYPES } from '../../modules/LinkGroup';
import { Link } from 'gatsby';

const FundsRows = props => {
  const { title, to, includeCols = [], downloads = [] } = props;
  let LinkWrapper = to ? Link : React.Fragment;
  return (
    <>
      <Table.Tr>
        <Table.Td>
          <LinkWrapper to={to}>{title}</LinkWrapper>
        </Table.Td>
        {includeCols && includeCols.length > 0 ? (
          includeCols.map(col => <Table.Td>{col.value}</Table.Td>)
        ) : (
          <Table.Td />
        )}
        {downloads.map(download => (
          <Table.Td>
            {download.file?.url && (
              <LinkGroup
                links={[
                  {
                    type: BUTTON_TYPES.DOWNLOAD,
                    as: 'a',
                    label: download.label,
                    external: true,
                    to: download.file.url,
                  },
                ]}
              />
            )}
          </Table.Td>
        ))}
      </Table.Tr>
    </>
  );
};

export default FundsRows;
